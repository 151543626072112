.form-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid rgba(173, 173, 174, 1); /* Default gray border */
    background-color: white;
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease-in-out;
    margin-right: 10px; /* Add margin-right to space out checkboxes */
}

.form-checkbox:checked {
    background-color: white;
    border: 3px solid #DB0000; /* Red border when selected */
}

.form-checkbox:checked::before {
    content: '\2713'; /* Unicode character for checkmark */
    display: block;
    color: #DB0000; /* Red checkmark */
    font-weight: bold; /* Make the tick bold */
    font-size: 18px; /* Increase the size slightly for more prominence */
    line-height: 30px; 
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
