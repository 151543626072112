/* Custom styling for the radio buttons */
.custom-radio {
  width: 1rem;
  height: 1rem;
  border: 1px solid #DEDEDE;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-radio.checked {
  background-color: #DB0000;
  border: 1px solid #DB0000;
}

.custom-radio .checkmark {
  color: white;
  font-size: 0.75rem;
}

/* Custom styling for the text */
.text-option {
  font-family: 'poppins';
  font-size: 17px;
  font-weight: 300;
  line-height: 20.25px;
  text-align: left;
  color: #535353;
}

/* Additional spacing for radio options */
.mb-6 {
  margin-bottom: 1.5rem;
}

/* Hide scrollbar but allow scrolling */
.scroll-container {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}