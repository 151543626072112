/* hexagon_body.css */
.hex-grid-container-wrapper {
  margin-top: 10px;
  flex-direction: column;
}

.hex-grid-container {
  position: relative;
  width: 60%;
  height: 65vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6%;

}

.hex-item {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .hex-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
} */

.logistics { top: -2%; left: 10%; }
.finance { top: 0%; left: 33%;width:18% }
.start_up { top: 6%; left: 54%; }
.legal { top: -2%; left: 73%;width:17% }
.jewelry{ top: 25%; left: 14%; padding: 5px;width:22%  }
.investor { top: 34%; left: 41%;  }
.seller { top: 33%; left: 65%; }
.market_place { top: 62%; left: 29%; }
.projects { top: 84%; left: 43%;}
.buyer { top: 63%; left: 56%; }

@media (max-width: 1285px) and (min-width: 769px) {
  .hex-grid-container {
    width: 100%;
    height: 80vh;
  }

  .logistics { top: 0; left: 5%; }
  .finance { top: 0; left: 28%; }
  .start_up { top: 8%; left: 46%; }
  .legal { top: 5%; left: 64%; }
  .jewelry{ top: 20%; left: 12%; padding: 5px; }
  .investor { top: 28%; left: 35%; }
  .seller { top: 28%; left: 55%; }
  .market_place { top: 50%; left: 25%; }
  .projects { top: 76%; left: 38%;  }
  .buyer { top: 50%; left: 48%; }
}



 @media (max-width: 700px) and (min-width:480px){

  .hex-grid-container {
    width: 70%;
    height:60vh;
    padding-top: 10px;
    margin-bottom: 120px; /* Add space for footer */
   } 

  .hex-item {
    width: 30%; 
    height: 30%; 
   } 

  .hex-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

   .logistics { top: -6%; left:18%;width:21% ;height:26%}
  .legal { top: -14%; left: 42%;width:23%;height:34%  }
  .finance { top: 14%; left: 30%;width:20% ;height:26% }
  .start_up { top: 10%; left: 52%;width: 22% ;height: 30%; }
  .jewelry{ top: 32%; left: 12%; width:32% ;height: 34%; }
  .seller { top: 34%; left: 42%;width:20% ;height:26%  }
  .investor { top: 57%; left: 28%; width: 26% ;height: 30%; }
   .buyer { top: 54%; left: 56%; width:18%;height:22%}
  .projects { top: 78%; left: 51%;width:24% ;height:22%}
  .market_place { top: 89%; left: 26%; width: 29%; height: 29%; } 



.footer {
  margin-top: auto;
  width: 100%;
} 
}

@media (max-width: 600px) and (min-width:500px){

  .hex-grid-container {
    width: 70%;
    height:60vh;
    padding-top: 10px;
    margin-bottom: 120px; /* Add space for footer */
   } 

  .hex-item {
    width: 30%; 
    height: 30%; 
   } 

  .hex-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

   .logistics { top: -6%; left:18%;width:21% ;height:26%}
  .legal { top: -14%; left: 42%;width:23%;height:34%  }
  .finance { top: 14%; left: 30%;width:20% ;height:26% }
  .start_up { top: 10%; left: 52%;width: 22% ;height: 30%; }
  .jewelry{ top: 32%; left: 5%; width:32% ;height: 34%; }
  .seller { top: 34%; left: 42%;width:20% ;height:26%  }
  .investor { top: 57%; left: 28%; width: 26% ;height: 30%; }
   .buyer { top: 54%; left: 56%; width:18%;height:22%}
  .projects { top: 78%; left: 51%;width:24% ;height:22%}
  .market_place { top: 89%; left: 26%; width: 29%; height: 29%; } 



.footer {
  margin-top: auto;
  width: 100%;
} 
}

@media (max-width: 700px) and (min-width: 480px) {
 
  .hex-grid-container {
    width: 80%;
    height:60vh;
    padding-top: 10px;
    margin-bottom: 120px; /* Add space for footer */
  }

  .hex-item {
    width: 30%; /* Adjusted to percentage */
    height: 30%; /* Adjusted to percentage */
  }

  .hex-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /* Adjust the positions for small screens */
  .logistics { top: 2%; left:4%;width:39% ;height:26%}
  .legal { top: -7%; left: 46%;width:40%;height:28%  }
  .finance { top: 22%; left: 27%;width:36% ;height:26% }
  .start_up { top: 14%; left: 64%;width: 38% ;height: 30%; }
  .jewelry{ top: 32%; left: -3%; width:42% ;height: 54%; }
  .seller { top: 42%; left: 44%;width:36% ;height:26%  }
  .investor { top: 69%; left: 28%; width: 35% ;height: 32%; }
   .buyer { top: 60%; left: 68%; width:28%;height:22%}
  .projects { top: 82%; left: 65%;width:26% ;height:22%}
  .market_place { top: 99%; left: 29%; width: 35%; height: 32%; } 
}


.footer {
  margin-top: auto;
  width: 100%;
}

/* 
 @media (max-width: 360px) {

  .hex-grid-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 1vh 1vw;
  }

  .hex-item {
    width: 28vw;
    height: 28vw;
  } */

  /* Adjust grid areas for smaller screens */
  /* .logistics { grid-area: 1 / 1 / 2 / 2; }
  .legal { grid-area: 1 / 3 / 2 / 4; }
  .financial_instituitions { grid-area: 2 / 2 / 3 / 3; }
  .start_up { grid-area: 3 / 1 / 4 / 2; }
  .jewelry{ grid-area: 3 / 2 / 4 / 4; }
  .seller { grid-area: 4 / 3 / 5 / 4; }
  .investor { grid-area: 5 / 1 / 6 / 3; }
  .buyer { grid-area: 5 / 3 / 6 / 4; }
  .projects { grid-area: 6 / 2 / 7 / 3; }
  .market_place { grid-area: 7 / 2 / 8 / 3; } */

 /* */ 


 @media (max-width: 480px) and (min-width:360px){



    .hex-grid-container {
      width: 80%;
      height:60vh;
      padding-top: 10px;
      margin-bottom: 120px; /* Add space for footer */
    }
  
    .hex-item {
      width: 30%; /* Adjusted to percentage */
      height: 30%; /* Adjusted to percentage */
    }
  
    .hex-item img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  
    /* Adjust the positions for small screens */
    .logistics { top: 2%; left:4%;width:39% ;height:26%}
    .legal { top: -7%; left: 46%;width:40%;height:28%  }
    .finance { top: 22%; left: 27%;width:36% ;height:26% }
    .start_up { top: 14%; left: 64%;width: 38% ;height: 30%; }
    .jewelry{ top: 32%; left: -8%; width:48% ;height: 54%; }
    .seller { top: 42%; left: 44%;width:36% ;height:26%  }
    .investor { top: 63%; left: 28%; width: 35% ;height: 32%; }
     .buyer { top: 60%; left: 68%; width:28%;height:22%}
    .projects { top: 82%; left: 65%;width:26% ;height:22%}
    .market_place { top: 87%; left: 29%; width: 35%; height: 32%; } 
  }
  
  
  .footer {
    margin-top: auto;
    width: 100%;
  }
  
 

  @media (max-width: 360px) {



    .hex-grid-container {
      width: 75%;
      height:65vh;
      margin-bottom: 30%;

    }
  
    .hex-item {
      width: 30%; 
      height: 30%; 
    }
  
    .hex-item img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  
    .logistics { top: 2%; left:4%;width:39% ;height:26%}
    .legal { top: -7%; left: 46%;width:43%;height:28%  }
    .finance { top: 22%; left: 27%;width:36% ;height:26% }
    .start_up { top: 14%; left: 64%;width: 38% ;height: 30%; }
    .jewelry{ top: 32%; left: -8%; width:48% ;height: 54%; }
    .seller { top: 42%; left: 44%;width:36% ;height:26%  }
    .investor { top: 63%; left: 28%; width: 39% ;height: 32%; }
     .buyer { top: 60%; left: 68%; width:28%;height:22%}
    .projects { top: 85%; left: 68%;width:28% ;height:20%}
    .market_place { top: 87%; left: 26%; width: 39%; height: 32%; } 
  }
  
  
  .footer {
    margin-top: auto;
    width: 100%;
  }

/* Styles for screens between 1440px and 2560px */





