.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000;
  }
  
  .loader {
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .loader div {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  
  .loader div:nth-child(1) {
    animation-delay: -0.45s;
    background-color: #fff;
  }
  .loader div:nth-child(2) {
    animation-delay: -0.3s;
    background-color: red;
  }
  .loader div:nth-child(3) {
    animation-delay: -0.15s;
    background-color: #fff;
  }
  .loader div:nth-child(4) {
    background-color: red;
  }
  
  @keyframes loader {
    0% {
      top: 36px;
      left: 66px;
      width: 0;
      height: 0;
    }
    25% {
      top: 18px;
      left: 18px;
      width: 16px;
      height: 16px;
    }
    50% {
      top: 36px;
      left: 0px;
      width: 0;
      height: 0;
    }
    75% {
      top: 18px;
      left: 66px;
      width: 16px;
      height: 16px;
    }
    100% {
      top: 36px;
      left: 66px;
      width: 0;
      height: 0;
    }
  }
  