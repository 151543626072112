.main-home-container .main-home-grid-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.main-home-container .main-home-grid {
  position: relative;
  width: 67%;
  height: 65vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6%;
}

.main-home-container .main-home-item {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .main-home-container .main-home-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
} */

.main-home-container .logistics { top: 0%; left: 8%; }
.main-home-container .finance { top: 0%; left: 33%; ; }
.main-home-container .start_up { top: 2%; left: 57%; }
.main-home-container .legal { top: 0%; left: 83%; }
.main-home-container .jewelry { top: 25%; left: 17%; padding: 5px; }
.main-home-container .investor { top: 30%; left: 41%; }
.main-home-container .seller { top: 28%; left: 70%; }
.main-home-container .market_place { top: 58%; left: 29%; }
.main-home-container .projects { top: 80%; left: 43%; }
.main-home-container .buyer { top: 58%; left: 59%; }

/* Responsive Design for screens */
@media (max-width: 1285px)  and (min-width: 769px) {
  .main-home-container .main-home-grid {
    width: 100%;
    height: 80vh;
  }

  .main-home-container .logistics { top: 0; left: 2%; }
  .main-home-container .finance { top: 0; left: 28%; }
  .main-home-container .start_up { top: 8%; left: 46%; }
  .main-home-container .legal { top: 5%; left: 64%; }
  .main-home-container .jewelry { top: 18%; left: 12%; width: 15%; }
  .main-home-container .investor { top: 28%; left: 35%; }
  .main-home-container .seller { top: 24%; left: 92%; }
  .main-home-container .market_place { top: 50%; left: 25%; }
  .main-home-container .projects { top: 68%; left: 38%; }
  .main-home-container .buyer { top: 52%; left: 6%; }
}

/* Adjustments for small screens */
@media (max-width: 768px) and (min-width:460px){
  .main-home-container .main-home-grid {
    width: 80%;
    height: 60vh;
  }

  .main-home-container .main-home-item {
    width: 30%;
    height: 30%;
  }
}


@media (max-width: 700px) and (min-width: 500px) {
  .main-home-container .main-home-grid {
    width: 75%;
    height: 65vh;
    padding-top: 10px;
    margin-bottom: 120px;
  }

  .main-home-container .main-home-item {
    width: 30%;
    height: 30%;
  }

  .main-home-container .main-home-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .main-home-container .logistics { top: 2%; left: 4%; width: 39%; height: 26%; }
  .main-home-container .legal { top: -7%; left: 46%; width: 40%; height: 28%; }
  .main-home-container .finance { top: 22%; left: 27%; width: 36%; height: 26%; }
  .main-home-container .start_up { top: 14%; left: 64%; width: 38%; height: 30%; }
  .main-home-container .jewelry { top: 32%; left: -3%; width: 42%; height: 54%; }
  .main-home-container .seller { top: 42%; left: 44%; width: 36%; height: 26%; }
  .main-home-container .investor { top: 67%; left: 28%; width: 35%; height: 32%; }
  .main-home-container .buyer { top: 60%; left: 68%; width: 28%; height: 22%; }
  .main-home-container .projects { top: 82%; left: 65%; width: 26%; height: 22%; }
  .main-home-container .market_place { top: 95%; left: 29%; width: 35%; height: 32%; }
}


@media (max-width: 499px) and (min-width: 360px) {
  .main-home-container .main-home-grid {
    width: 80%;
    height: 60vh;
    padding-top: 10px;
    margin-bottom: 120px; /* Add space for footer */
  }

  .main-home-container .main-home-item {
    width: 30%;
    height: 30%;
  }

  .main-home-container .main-home-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /* Adjust the positions for small screens with the same class names */
  .main-home-container .logistics { top: 2%; left: 4%; width: 39%; height: 26%; }
  .main-home-container .legal { top: -7%; left: 46%; width: 40%; height: 28%; }
  .main-home-container .finance { top: 22%; left: 27%; width: 36%; height: 26%; }
  .main-home-container .start_up { top: 14%; left: 64%; width: 38%; height: 30%; }
  .main-home-container .jewelry { top: 32%; left: -8%; width: 48%; height: 54%; }
  .main-home-container .seller { top: 42%; left: 44%; width: 36%; height: 26%; }
  .main-home-container .investor { top: 63%; left: 28%; width: 35%; height: 32%; }
  .main-home-container .buyer { top: 60%; left: 68%; width: 28%; height: 22%; }
  .main-home-container .projects { top: 82%; left: 65%; width: 26%; height: 22%; }
  .main-home-container .market_place { top: 87%; left: 29%; width: 35%; height: 32%; }

  .footer {
    margin-top: auto;
    width: 100%;
  }
}




@media (max-width: 360px) {
  .main-home-container .main-home-grid {
    width: 75%;
    height: 65vh;
    margin-bottom: 30%;
  }

  .main-home-container .main-home-item {
    width: 30%;
    height: 30%;
  }

  .main-home-container .main-home-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /* Adjust the positions for small screens with the same class names */
  .main-home-container .logistics { top: 2%; left: 4%; width: 39%; height: 26%; }
  .main-home-container .legal { top: -7%; left: 46%; width: 43%; height: 28%; }
  .main-home-container .finance { top: 22%; left: 27%; width: 36%; height: 26%; }
  .main-home-container .start_up { top: 14%; left: 64%; width: 38%; height: 30%; }
  .main-home-container .jewelry { top: 32%; left: -8%; width: 48%; height: 54%; }
  .main-home-container .seller { top: 42%; left: 44%; width: 36%; height: 26%; }
  .main-home-container .investor { top: 63%; left: 28%; width: 39%; height: 32%; }
  .main-home-container .buyer { top: 60%; left: 68%; width: 28%; height: 22%; }
  .main-home-container .projects { top: 85%; left: 68%; width: 28%; height: 20%; }
  .main-home-container .market_place { top: 87%; left: 26%; width: 39%; height: 32%; }

  .footer {
    margin-top: auto;
    width: 100%;
  }
}
@media (min-width: 1800px) {
  .main-home-container .main-home-grid {
    width: 80%; /* Increase width for larger monitors */
    height: 70vh; /* Increase the height proportionately */
    margin: 0 auto; /* Center the grid */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-home-container .main-home-item {
    width: 26%; /* Adjust the size for better visibility */
    height: 26%;
  }

  /* Adjust positions to use larger screen space effectively */
  .main-home-container .logistics { top: 5%; left: 8%; }
  .main-home-container .finance { top: 8%; left: 30%; }
  .main-home-container .start_up { top: 10%; left: 55%; }
  .main-home-container .legal { top: 7%; left: 78%; }
  .main-home-container .jewelry { top: 35%; left: 20%; }
  .main-home-container .investor { top: 38%; left: 42%; }
  .main-home-container .seller { top: 36%; left: 66%; }
  .main-home-container .market_place { top: 65%; left: 30%; }
  .main-home-container .projects { top: 85%; left: 42%; }
  .main-home-container .buyer { top: 65%; left: 57%; }

  /* Ensure images scale proportionally */
  .main-home-container .main-home-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /* Adjust footer for larger screens */
  .footer {
    margin-top: auto;
    width: 100%;
    padding: 30px; /* Add padding for spacing */
  }
}