.otp-input {
  width: 100%;
  max-width: 70px;
  height: 55px;
  margin: 0 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid rgba(162, 162, 162, 1);
  background: rgba(252, 251, 251, 1);
}

@media (max-width: 640px) {
  .otp-input {
    width: 100%;
    max-width: 50px;
    height: 26px;
    font-size: 14px;
    margin: 0 2px;
  }
}

.otp-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
}

.footer-container {
  position: relative;
}

.desktop-view {
  display: none;
}

.mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .desktop-view {
    display: block;
  }

  .mobile-view {
    display: none;
  }
}
