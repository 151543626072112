@tailwind base;
@tailwind components;
@tailwind utilities;


/* Default large screens (e.g., desktops) */
.large-screen-container {
  width: 84%;
  margin: 0 auto;
}

/* Ultra-large screens (e.g., 4K monitors) */
@media screen and (min-width: 2560px) {
  .large-screen-container {
      width: 98%; /* Set to 98% for 4K screens */
  }
}



@font-face {
    font-family: 'Techna Sans';
    src: local('Techna Sans'), url('./fonts/techna-sans.otf') format('opentype');
    
  }
  
  @font-face {
    font-family: 'Helvetica Light';
    src: local('Helvetica Light'), url('./fonts/helvetica-light.ttf') format('truetype');
   
  }
  @font-face {
    font-family: 'Gotham';
    src: url('./fonts/FontsFree-Net-GothamBook.ttf') format('truetype'); /* Adjust the path if necessary */
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Book';
    src: url('./fonts/Gotham-Book.ttf') format('truetype');
    
  }

  @font-face {
    font-family: 'Gotham-XLight';
    src: url('./fonts/Gotham-XLight.ttf') format('truetype');
   
  }
  @font-face {
    font-family: 'Gotham-Bold';
    src: url('./fonts/Gotham-Bold.ttf') format('truetype');
 
  }
  @font-face {
    font-family: 'metropolis';
    src: url('./fonts/Metropolis-Regular.otf') format('truetype');
 
  }
  
  
  