/* Hide the search icon in the country dropdown */
.react-tel-input .country-list .search-box .search-icon {
  display: none !important;
}

/* Additional styling for the country dropdown and input */
.react-tel-input .flag-dropdown {
  background-color: #FFFFFF !important;
}

.react-tel-input .selected-flag {
  background-color: #FFFFFF !important;
  padding: 8px !important;
}

.react-tel-input .country-list {
  background-color: #FFFFFF !important;
  padding: 8px !important;
  max-height: 208px !important;
  overflow-y: auto !important;
}

.react-tel-input .country-list .country {
  padding: 8px !important;
  cursor: pointer !important;
}

.react-tel-input .country-list .country:hover,
.react-tel-input .country-list .country.highlight {
  background-color: #FFFFFF !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  padding-left: 56px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  background-color: #FFFFFF !important;
  /* border: 1px solid #FFFFFF !important; */
}

.react-tel-input .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
/* Use :not() to force hiding the search icon */
.react-tel-input .search-box:not(.hide) .search-icon {
  display: none !important;
}

/* Additionally, target the span element directly to ensure it's hidden */
.react-tel-input .search-box .search-icon span {
  display: none !important;
}
/* Specifically target the search icon inside the country dropdown */
.react-tel-input .country-list .search-box .search-icon {
  display: none !important; /* Ensure the icon is completely hidden */
}

.react-tel-input .search-box input {
  padding-left: 5px !important; /* Adjust padding if needed after removing icon */
}
