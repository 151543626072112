
.Logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(180deg, #B71C1C 0%, #DB0000 100%);
  z-index: 1;
  background-size: 400% 400%;
  background-color: #DB0000;
}
*{
  margin:0;
  padding:0;
}