.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image2 {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}